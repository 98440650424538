<template>
    <div class="content-wrapper overflow-visible">
        <div class="content pt-0">
            <div class="card mb-1">
            <div class="card-body">
                <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div v-if="row.aur_is_emergency == 'Y'" class="badge bg-danger mt-2">
                        Emergency
                    </div>

                    <div class="badge badge-dark mb-1 mr-1" v-if="row.aur_is_void == 'Y'">
                        VOID
                    </div>
                    <template v-else>
                        <div class="badge bg-warning mb-1 mr-1" v-if="row.aures_is_draft == 'Y'">
                            Draft
                        </div>

                        <div class="badge bg-warning mb-1 mr-1" v-if="row.aur_have_triase == 'N'">
                            Perlu Triase
                        </div>
                        
                        <div class="badge bg-warning mb-1 mr-1" v-if="row.ap_is_active != 'Y'">
                            Lengkapi Data Pasien
                        </div>

                        <div class="badge bg-warning mb-1 mr-1" v-if="row.aures_is_need_data == 'Y'">
                            Lengkapi Data Reservasi
                        </div>
                        
                        <div v-if="row.aur_status != 1" :class="`badge ${row.aus_color}`">{{uppercaseWord(row.aus_name)||'-'}}</div>
                    </template>

                    <p class="mb-0 mt-1"><span class="text-success font-weight-semibold">{{rowPasien.ap_gol_usia||"-"}}</span></p>
                    <p v-if="row.aur_is_emergency == 'Y' && row.aures_jaminan != 3" class="mb-0 mt-1"><small>Silakan ubah cara bayar menjadi BPJS Kesehatan untuk melanjutkan pembuatan SEP</small></p>
                </div>
                <div class="col-md-5">
                    <router-link href="javascript:;" :to="{name: 
                    $route.name, params: {pageSlug: row.aures_id}}" 
                    class="btn float-right btn-primary btn-labeled btn-labeled-left">
                    <b><i class="icon-pencil7"></i></b>
                    Edit Data
                    </router-link>
                    
                    <a href="javascript:;" v-if="row.aures_jaminan == 3 && row.showSEP" @click="addSEPDataKontrol" data-popup="tooltip" title="Buat SEP" data-toggle="modal"
                    class="btn float-right mr-1 btn-success btn-labeled btn-labeled-left">
                    <b><i class="icon-check"></i></b>
                    Buat SEP
                    </a>

                    <router-link :to="{name: 'BPJSDataSEP', params: {pageSlug: row.idSEP}, query:{ detail: row.noSEP ? true : ''}}" v-if="row.aures_jaminan == 3 && !row.showSEP" class="btn float-right btn-warning mr-1 btn-labeled btn-labeled-left">
                        <b><i class="icon-eye"></i></b>
                        <span v-if="row.noSEP"> Lihat SEP</span>
                        <span v-else>Buat SEP</span>
                    </router-link>

                    <a href="javascript:;" v-b-tooltip.hover="'Buat Ulang SEP'" @click="deleteSEP()" v-if="row.aures_jaminan == 3 && !row.showSEP" class="btn float-right btn-info mr-1">
                        <b><i class="icon-upload"></i></b>
                        Buat Ulang SEP
                    </a>
                </div>
                </div>
            </div>
            </div>

            
            <div class="card mb-1">
                <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
                    <li class="nav-item"><a @click="changeTabU(1)" href="javascript:;" 
                    :class="activeTabU == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Informasi Reservasi</a></li>
                    <li class="nav-item"><a @click="changeTabU(2)" href="javascript:;" 
                    :class="activeTabU == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Persetujuan Umum</a></li>
                </ul>
                <div class="tab-content">
                    <div :class="activeTabU == 1 ? 'tab-pane fade active show' : 'tab-pane fade'" id="infoReservasi">
                        <div class="card-header bg-white">
                            <h6 class="card-title font-weight-semibold">Data Reservasi  {{row.aur_reg_code}} - {{rowPasien.ap_fullname||"-"}}</h6>
                        </div>
                        <div class="card-body">

                        

                            <div class="alert alert-info mb-0" v-if="row.aur_is_emergency == 'Y' && row.aures_jaminan != 3">
                                <div class="d-flex align-items-center">
                                    <i class="icon-info22"></i>
                                    <span class="ml-2">Silakan ubah cara bayar Pasien menjadi BPJS Kesehatan</span>
                                </div>
                            </div>

                            <div class="row">
                            <div class="col-md-8">
                                <table class="table table-borderless table-sm text-uppercase">
                                    <tbody>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Rekam Medis</h4>
                                            <p>{{rowPasien.ap_code||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Cara Bayar</h4>
                                            <p>{{row.mcp_name || "-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Pemeriksaan </h4>
                                            <p>UGD</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Tanggal, Jam Masuk UGD</h4>
                                            <p v-if="row.aur_reg_date">{{row.aur_reg_date | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                                            <p v-else> - </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Diperiksa Oleh</h4>
                                            <p>{{row.bu_full_name||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Tipe Reservasi</h4>
                                            
                                            <span v-if="row.aures_type == 'Q'" :class="`badge bg-success`">Reservasi Cepat</span>
                                            <span v-else :class="`badge bg-info`">Reservasi Normal</span>
                                            
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div :class="activeTabU == 2 ? 'tab-pane fade active show' : 'tab-pane fade'" id="infoReservasi">
                        <div class="card-header bg-white">
                            <h6 class="card-title font-weight-semibold">Informasi Persetujuan Umum</h6>
                        </div>
                        <div class="card-body">
                            <div class="row g-2">
                            <div class="col-md-4">
                                <div class="result_tab">
                                <h4>Persetujuan Dilakukan Oleh</h4>
                                <p v-if="row.aures_pu_pic">{{row.aures_pu_pic == 'P' ? 'Pasien' : 'Wali hukum'}}</p>
                                <p v-else> - </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="result_tab">
                                <h4>Wewenang Pelepasan Informasi Kepada</h4>
                                <p>{{wewenang||"-"}}</p>
                                </div>
                            </div>
                            <!--
                            <div class="col-md-4">
                                <div class="result_tab">
                                <h4>Partisipasi Mahasiswa dalam perawatan pasien</h4>
                                <p v-if="row.aures_pu_info_ranap">{{row.aures_pu_info_ranap == 'Y' ? 'Setuju' : 'Tidak Setuju'}}</p>
                                <p v-else> - </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="result_tab">
                                <h4>Akses Menengok Pasien</h4>
                                <p v-if="row.aures_pu_privasi">{{row.aures_pu_privasi == 'Y' ? 'Mengizinkan' : 'Tidak Mengizinkan'}}</p>
                                <p v-else> - </p>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="result_tab">
                                <h4>Nama yang tidak diizinkan </h4>
                                <p>{{row.aures_pu_name||"-"}}</p>
                                </div>
                            </div>
                            -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" data-toggle="tab" data-target="#pasienTabInfo"
                        :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Informasi Pasien</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" data-toggle="tab" data-target="#pasienTabPJ"
                        :class="activeTab == 2 ? 'nav-link active' : 'nav-link'">Informasi Penanggung Jawab</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" data-toggle="tab" data-target="#pasienTabKW"
                        :class="activeTab == 3 ? 'nav-link active' : 'nav-link'">Informasi Kewenangan</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" data-toggle="tab" data-target="#pasienTabKW"
                        :class="activeTab == 4 ? 'nav-link active' : 'nav-link'">Dokumen</a></li>
                </ul>
                <div class="tab-content">
                    <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'"  id="pasien">
                        <div class="card-body">
                            <table class="table table-borderless table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                        <div class="result_tab">
                                            <h4>No. Rekam Medis</h4>
                                            <p>{{rowPasien.ap_code||"-"}}</p>
                                        </div>
                                        </td>
                                        <td width="33%">
                                        <div class="result_tab">
                                            <h4>Nama</h4>
                                            <p>{{rowPasien.ap_fullname||"-"}}</p>
                                        </div>
                                        </td>
                                        <td width="33%">
                                        <div class="result_tab">
                                            <h4>NIK</h4>
                                            <p>{{rowPasien.ap_nik}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Tempat, Tanggal Lahir</h4>
                                            <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob_true | moment("DD MMM YYYY")}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Usia</h4>
                                            <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Kategori Usia</h4>
                                            <p>{{rowPasien.ap_gol_usia||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Jenis Kelamin</h4>
                                            <p>{{rowPasien.cg_label||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Agama</h4>
                                            <p v-if="rowPasien.ma_id !== 99999">{{rowPasien.ma_name||"-"}}</p>
                                            <p v-else>{{rowPasien.ap_agama_text||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Suku</h4>
                                            <p>{{rowPasien.mrsuk_name||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Pekerjaan</h4>
                                            <p v-if="rowPasien.mp_id !== 99999">{{rowPasien.mp_name||"-"}}</p>
                                            <p v-else>{{rowPasien.ap_pekerjaan_text||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Pendidikan</h4>
                                            <p>{{rowPasien.mpen_name||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Nama Ibu Kandung</h4>
                                            <p>{{rowPasien.ap_nama_ibu||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>No. Telpon</h4>
                                            <p>+62{{rowPasien.ap_phone_number||"-"}}</p>
                                        </div>
                                        </td>
                                        <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Email</h4>
                                            <p v-if="rowPasien.ap_email">{{rowPasien.ap_email.trim()||"-"}}</p>
                                            <p v-else> - </p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Status Pernikahan</h4>
                                            <p v-if="rowPasien.ap_status_pernikahan == 1">Belum Kawin</p>
                                            <p v-else-if="rowPasien.ap_status_pernikahan == 2">Kawin</p>
                                            <p v-else-if="rowPasien.ap_status_pernikahan == 3">Cerai Hidup</p>
                                            <p v-else-if="rowPasien.ap_status_pernikahan == 4">Cerai Mati</p>
                                            <p v-else> - </p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Bahasa yang Dikuasai</h4>
                                            <p>{{rowPasien.ap_language||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Golongan Darah</h4>
                                            <p v-if="rowPasien.ap_gol_darah">{{rowPasien.ap_gol_darah.trim()||"-"}}</p>
                                            <p v-else> - </p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                        <div class="result_tab">
                                            <h4>Alamat Domisili</h4>
                                            <p>{{domisiliAddr||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                        <div class="result_tab">
                                            <h4>Alamat Sesuai KTP</h4>
                                            <p>{{ktpAddr||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="penanggung-jawab">
                        <div class="card-body">
                            <table class="table table-borderless table-sm text-uppercase">
                                <tbody>
                                <tr>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Hubungan Keluarga</h4>
                                        <p>{{rowPasien.arpj_hubungan_keluarga_text||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Nama Penanggung Jawab</h4>
                                        <p>{{rowPasien.arpj_fullname||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Pekerjaan </h4>
                                        <p v-if="rowPasien.arpj_pekerjaan !== 99999">{{rowPasien.pekerjaan_pj||"-"}}</p>
                                        <p v-else>{{rowPasien.arpj_pekerjaan_text||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{rowPasien.gender_pj||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Agama </h4>
                                        <p v-if="rowPasien.arpj_agama !== 99999">{{rowPasien.agama_pj||"-"}}</p>
                                        <p v-else>{{rowPasien.arpj_agama_text||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>No Handphone</h4>
                                        <p>+62{{rowPasien.arpj_phone_number}}</p>
                                    </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="3">
                                    <div class="result_tab">
                                        <h4>Alamat Domisili</h4>
                                        <p>{{pjAddr||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                        
                    <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane fade'" id="informasi-kewenangan">
                        <div class="card-body">
                            <table class="table table-borderless table-sm text-uppercase">
                                <tbody>
                                <tr>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Hubungan Keluarga</h4>
                                        <p>{{rowPasien.arki_hubungan_keluarga_text||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Nama Penanggung Jawab</h4>
                                        <p>{{rowPasien.arki_fullname||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Pekerjaan </h4>
                                        <p v-if="rowPasien.arki_pekerjaan !== 99999">{{rowPasien.pekerjaan_ki||"-"}}</p>
                                        <p v-else>{{rowPasien.arki_pekerjaan_text||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{rowPasien.gender_ki||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Agama </h4>
                                        <p v-if="rowPasien.arki_agama !== 99999">{{rowPasien.agama_ki||"-"}}</p>
                                        <p v-else>{{rowPasien.arki_agama_text||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>No Handphone</h4>
                                        <p>+62{{rowPasien.arki_phone_number}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                    <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane fade'" id="dokumen">
                        <div class="card-body">
                            <table class="table table-borderless table-sm text-uppercase">
                            <tbody>
                                <tr v-if="row.aures_jaminan != 3">
                                <td width="25%">
                                    <div class="result_tab">
                                    <h4>Foto KTP/KK</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_file_ktp" :href="$parent.uploader(row.aures_file_ktp)" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td width="25%">
                                    <div class="result_tab">
                                    <h4>Foto Pasien</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_file_foto_pasien" :href="$parent.uploader(row.aures_file_foto_pasien)" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td width="25%" v-if="row.aures_jaminan == 2">
                                    <div class="result_tab">
                                    <h4>Foto Kartu Asuransi</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_file_asuransi" :href="$parent.uploader(row.aures_file_asuransi)" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td width="25%">
                                    <div class="result_tab">
                                    <h4>General Consent</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_template" @click="downloadFile(row.aures_id,'SuratGeneralConsent')" :href="'javascript:;'" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                </tr>
                                <tr v-else>
                                <td width="25%">
                                    <div class="result_tab">
                                    <h4>Foto Kartu BPJS</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_kartu_bpjs" :href="$parent.uploader(row.aures_kartu_bpjs)" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td width="25%">
                                    <div class="result_tab">
                                    <h4>Foto Surat Rujukan</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_surat_rujukan" :href="$parent.uploader(row.aures_surat_rujukan)" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td width="25%">
                                    <div class="result_tab">
                                    <h4>Foto Surat Kontrol</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_surat_kontrol" :href="$parent.uploader(row.aures_surat_kontrol)" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td width="25%">
                                    <div class="result_tab">
                                    <h4>General Consent</h4>
                                    <div class="d-flex align-items-center mt-1">
                                        <div>
                                        <a v-if="row.aures_template" @click="downloadFile(row.aures_id,'SuratGeneralConsent')" :href="'javascript:;'" data-popup="tooltip" title=""
                                            class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                            data-original-title="Download"><i class="icon-download7
                                        "></i></a>
                                        <span v-else> - </span>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <validation-observer
                ref="VFormKontrol"
            >
                <b-modal title="Buat SEP" size="sm" v-model="modalSEPKontrol" @ok.prevent="addSEPData">
                    <div class="form-group">
                        <label for="">Pilih Tipe SEP</label>
                        <v-select disabled placeholder="Pilih Jenis Tipe SEP" v-model="isType"
                            :options="Config.mr.mTypeSEPUGD" label="text" :clearable="true"
                            :reduce="v=>v.value">
                        </v-select>
                        <VValidate  
                            name="Pengajuan SEP" 
                            v-model="isType" 
                            :rules="{required: 1}">
                        </VValidate>
                    </div>
                </b-modal>
            </validation-observer>
        </div>
    </div>
</template>


<script>
    import $ from 'jquery'
    const _ = global._
    import Gen from '@/libs/Gen.js'
    import Config from '@/libs/Config'
    const moment = require('moment')
    // import Datepicker from 'vuejs-datepicker'

    export default{
        // components: {
        //     Datepicker
        // },    
        data(){
            return {
                activeTabU: 1,                
                activeTab: 1,
                rowPasien: {},
                openVerif: false,
                openVoid: false,
                modalSEPKontrol: false,
                waktu : null,
                isType : "I",
            }
        },
        props:{
            row:Object,
            mrValidation:Object
        },
        methods: {
            downloadFile(id,val){
                let data = {exptype: 'xlsx', type: "export", id: id}
                let self = this
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${val}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.$parent.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.$parent.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            changeTabU(e){
                this.activeTabU = e
            },
            deleteSEP(){
                let data = {
                    type : 'delete-sep',
                    regId : this.row.aur_id,
                }
                this.$parent.loadingOverlay = true
                Gen.apiRest(
                    "/do/" + this.$parent.modulePage, {
                        data: data
                    },
                "POST"
                ).then(res => {
                    this.$parent.apiGet()
                    this.$parent.loadingOverlay = false
                    this.addSEPDataKontrol()
                })
            },
            addSEPDataKontrol(){
                this.modalSEPKontrol = true
            },
            apiGetMaster(){
                Gen.apiRest(
                    "/get/"+this.$parent.modulePage+'/master' 
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            back(){
                this.$router.back()
            },
            getConfigDynamic(master, value, val = 'text') {
                let text = ''
                if (value) {
                    let index = (master||[]).findIndex(x => x.value == value)
                    if (index !== -1) {
                    text = master[index][val]
                    }
                }
                return text
            },
            changeTab(e){
                this.activeTab = e
            },
            apiGetPasien(){
                this.$parent.loadingOverlay = true            
                Gen.apiRest(
                    "/get/"+"Pasien"+'/'+this.row.aures_ap_id 
                ).then(res=>{
                    this.$parent.loadingOverlay = false
                    this.rowPasien = res.data.row
                })
            },
            
            uppercaseWord(e=""){
                if(e){
                    const words = String(e)
                    return words.toUpperCase()
                }else{
                    return null
                }
            },
            addSEPData(){
                let data = {
                    type : 'add-sep',
                    regId : this.row.aur_id,
                    pasienId : this.row.aures_ap_id,
                    resId : this.row.aures_id,
                    waktu : this.waktu,
                    isType : this.isType
                }
                this.$parent.loadingOverlay = true
                this.modalSEPKontrol = false
                Gen.apiRest(
                    "/do/" + this.$parent.modulePage, {
                        data: data
                    },
                "POST"
                ).then(res => {
                    let resp = res.data
                    this.$parent.loadingOverlay = false
                    return this.$swal({
                        title: resp.message,
                        confirmButtonText: 'Lihat SEP',
                        icon: 'success',
                    }).then(()=>{
                        this.$router.push({name : 'BPJSDataSEP', params:{pageSlug : res.data.data.id}}).catch(()=>{})
                    })
                }).catch(err=>{ 
                    this.$parent.loadingOverlay = false 
                    if(err){
                        err.statusType = err.status
                        err.status = "error"
                        err.message = err.response.data.message
                        err.title = err.response.data.title
                        err.messageError = err.message
                    }
                    this.$parent.doSetAlertForm(err)
                })
            },
        },
        computed: {    
            Config(){ return Config },
            
            wewenang(){
                let datawewenang = []
                for(let i = 0; i < (this.row.aures_penerima_info||[]).length; i++){
                    datawewenang.push(
                        this.row.aures_penerima_info[i].nama + " (" +this.row.aures_penerima_info[i].hub +"," + this.row.aures_penerima_info[i].tel + ")"
                    )
                }
                return (datawewenang).join(", ")
            },

            ktpAddr(){
                if(this.rowPasien.ap_address && this.rowPasien.ap_rt && this.rowPasien.ap_rw && this.rowPasien.ap_kelurahan && this.rowPasien.ap_kecamatan && this.rowPasien.ap_kota){
                    return (this.rowPasien.ap_address||"-") + "RT."+(this.rowPasien.ap_rt||"-")+"/"+(this.rowPasien.ap_rw||"-")+", DESA "+
                    (this.rowPasien.ap_kelurahan||"-")+", KEC."+(this.rowPasien.ap_kecamatan||"-")+ "KAB/KOTA"+(this.rowPasien.ap_kota||"-")
                }else{
                    return "-"
                }
            },

            domisiliAddr(){
                if(this.rowPasien.ap_dom_address && this.rowPasien.ap_dom_rt && this.rowPasien.ap_dom_rw && this.rowPasien.ap_dom_kelurahan && this.rowPasien.ap_dom_kecamatan && this.rowPasien.ap_dom_kota){
                    return (this.rowPasien.ap_dom_address||"-") + "RT."+(this.rowPasien.ap_dom_rt||"-")+"/"+(this.rowPasien.ap_dom_rw||"-")+", DESA "+
                    (this.rowPasien.ap_dom_kelurahan||"-")+", KEC."+(this.rowPasien.ap_dom_kecamatan||"-")+ "KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
                }else{
                    return "-"
                }
            },
            
            pjAddr(){
                if(this.rowPasien.arpj_address && this.rowPasien.arpj_rt && this.rowPasien.arpj_rw && this.rowPasien.arpj_kelurahan && this.rowPasien.arpj_kecamatan && this.rowPasien.arpj_kota){
                    return (this.rowPasien.arpj_address||"-") + "RT."+(this.rowPasien.arpj_rt||"-")+"/"+(this.rowPasien.arpj_rw||"-")+", DESA "+
                    (this.rowPasien.arpj_kelurahan||"-")+", KEC."+(this.rowPasien.arpj_kecamatan||"-")+ "KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
                }else{
                    return "-"
                }
            },
        },
        mounted() {
            window.scrollTo(0,0)
            setTimeout(()=>{
                this.apiGetMaster()
                this.apiGetPasien()
            },1500)
        }
        
    }
</script>


