<template>
  <div class="content">
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit" class="card">
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Reservasi Pasien UGD</h6>
          </div>

          <div class="card-header p-0" v-if="row.aures_is_draft != 'N'">
            <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
              <li class="nav-item"><a href="javascript:;" @click="changeTab('N')"
                  :class="row.aures_type == 'N' ?'nav-link active' : 'nav-link'" data-toggle="tab">Reservasi Normal</a>
              </li>
              <li class="nav-item"><a href="javascript:;" @click="changeTab('Q')"
                  :class="row.aures_type == 'Q' ?'nav-link active' : 'nav-link'" data-toggle="tab">Reservasi Cepat</a>
              </li>
            </ul>
          </div>
          <div class="tab-content">
            <div v-if="(row.aures_type == 'N' || row.aures_is_draft == 'N')"
              :class="(row.aures_type == 'N' || row.aures_is_draft == 'N') ? 'tab-pane fade show active card-body border-0 p-3' : 'tab-pane fade card-body border-0 p-3'"
              id="ugdNormalReservation">
              <div class="wrap_line_heading">
                <div class="data_prev_cont">
                  <div class="card">
                    <div class="card-header bg-light py-2">
                      <h6 class="card-title font-weight-semibold">Pasien</h6>
                    </div>
                    <div class="card-body">
                      <div>
                        <label>Filter Data Pasien</label>
                        <div class="row">
                          <div class="col-md">
                            <div class="form-row">
                              <div class="col-md-3">
                                <b-form-input v-model="nama_pasien" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nama Pasien" />
                              </div>
                              <div class="col-md-3">
                                <b-form-input v-model="no_rm" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nomor Rekam Medis" />
                              </div>
                              <div class="col-md-3">
                                <b-form-input v-model="alamat" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Alamat" />
                              </div>
                              <div class="col-md-3">
                                <b-form-input v-model="ttl" v-mask="'##/##/####'" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Tanggal Lahir" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-auto">
                            <div class="d-flex">
                              <a href="javascript:;" @click="searchPasien()" class="btn-new mr-1">Cari</a>
                              <a href="javascript:;" @click="resetPasien()" class="btn-new">Reset</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div>
                        <label>Nama Pasien <small class="txt_mandatory">*</small></label>
                        <div class="row gy-1">
                          <div class="col-12">
                            <v-select class="diagnosa-section select-paging" placeholder="-- Pilih Pasien -- "
                              v-model="row.aures_ap_id" :disabled="row.aures_is_draft != 'Y'" @search="filterName($event)"
                              @input="selectName($event)" :options="mPasien" label="text" :clearable="true"
                              :reduce="v=>v.value">

                              <template slot="selected-option">
                                <span v-b-tooltip.hover.right :title="row.ap_fullname">
                                  {{row.ap_fullname||"-"}} - {{row.ap_code||"-"}}
                                  <template v-if="row.ap_dob">
                                    -
                                    {{row.ap_dob | moment("DD MMMM YYYY")}}
                                  </template>
                                  <template v-else>
                                    -
                                    Tgl Lahir Kosong
                                  </template>
                                  - {{row.ap_dom_address||"Alamat Kosong"}}
                                </span>
                              </template>

                              <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover.right :title="option.text">
                                  {{ option.text }}
                                </span>
                              </template>

                              <template #list-footer v-if="(mPasien||[]).length >= 1">
                                <li class="list-paging">
                                  <div class="d-flex align-items-center justify-content-between">
                                    <b-button @click="changePage(mPasien[0].currentPage,'min')" size="sm"
                                      variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{mPasien[0].currentPage}} dari
                                      {{ceilData(mPasien[0].totalRows/10)}}</small>
                                    <b-button @click="changePage(mPasien[0].currentPage,'plus')" size="sm"
                                      variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-right22 text-white"></i></b-button>
                                  </div>
                                </li>
                              </template>
                            </v-select>

                            <VValidate name="Nama Pasien" v-model="row.aures_ap_id"
                              :rules="toValidate(mrValidation.aures_ap_id)" />
                          </div>
                          <div class="col-12" v-if="row.aures_is_draft != 'N'">
                            <div class="d-flex justify-content-end">
                              <a href="javascript:;" @click="addNewPasien" class="btn-new mt-2 mr-1"><i class="icon-user-plus"></i>Tambah Pasien Baru </a>
                              <a href="javascript:;" @click="modalGenerate=true" class="btn-new mt-2"><i class="icon-user-plus"></i> Generate Pasien Baru </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body bg_card_blue" v-if="Object.keys(rowPasien||{}).length && rowPasien.ap_code">
                      <div class="row">
                        <div class="col-md-9">
                          <table class="table table-borderless table-sm text-uppercase">
                            <tbody>
                              <tr>
                                <td width="33%">
                                  <div class="result_tab">
                                    <h4>No. Rekam Medis</h4>
                                    <p>{{rowPasien.ap_code||"-"}}</p>
                                  </div>
                                </td>
                                <td width="33%">
                                  <div class="result_tab">
                                    <h4>Nama Pasien</h4>
                                    <p>{{rowPasien.ap_fullname||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td width="33%">
                                  <div class="result_tab">
                                    <h4>Tempat, Tanggal Lahir </h4>
                                    <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Usia</h4>
                                    <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{rowPasien.cg_label||"-"}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Alamat</h4>
                                    <p>{{rowPasien.ap_dom_address||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-4">
                      <label>Asal Rujukan <small class="txt_mandatory">*</small></label>
                      <v-select :disabled="!row.aures_ap_id?true:false" placeholder=" -- Pilih Asal Rujukan -- "
                        v-model="row.aures_asal_rujukan" :options="Config.mr.statusUGDRujukan" label="text"
                        :clearable="true" :reduce="v=>v.value">
                      </v-select>
                      <VValidate name="Asal Rujukan" v-model="row.aures_asal_rujukan"
                        :rules="toValidate(mrValidation.aures_asal_rujukan)" />
                    </div>


                    <div v-if="row.aures_asal_rujukan == 2" class="form-group col-md-4">
                      <label>Dokter <small class="txt_mandatory">*</small></label>
                      <div class="row">
                        <v-select class="col-md-8" :disabled="!row.aures_ap_id?true:false"
                          placeholder=" -- Pilih Dokter -- " v-model="row.aures_dokter" :options="mDokterUGD"
                          label="text" :clearable="true" :reduce="v=>v.value">
                        </v-select>
                        <a href="javascript:;" @click="row.aures_dokter = 99999" class="btn-new col-md-4"><i
                            class="icon-plus2"></i> Tambah </a>

                        <div class="mt-2 ml-2" v-if="row.aures_dokter == 99999">
                          <label>Dokter Lainnya<small class="txt_mandatory">*</small></label>
                          <b-form-input v-model="row.aures_dokter_lainnya" type="text" name="bahasa" id="bahasa"
                            class="form-control" placeholder="Dokter Lainnya" />

                          <VValidate name="Dokter Lainnya" v-model="row.aures_dokter_lainnya"
                            :rules="toValidate(mrValidation.aures_dokter_lainnya)" />
                        </div>
                      </div>

                      <VValidate name="Dokter" v-model="row.aures_dokter" :rules="{required : 1}" />
                    </div>

                    <div v-if="row.aures_asal_rujukan == 3" class="form-group col-md-4">
                      <label>Rumah Sakit <small class="txt_mandatory">*</small></label>
                      <div class="row">
                        <v-select class="col-md-8" :disabled="!row.aures_ap_id?true:false"
                          placeholder=" -- Pilih Rumah Sakit -- " v-model="row.aures_rs" :options="mUGDRS" label="text"
                          :clearable="true" :reduce="v=>v.value">
                        </v-select>
                        <a href="javascript:;" @click="row.aures_rs = 99999" class="btn-new col-md-4"><i
                            class="icon-plus2"></i> Tambah </a>
                      </div>

                      <VValidate name="Rumah Sakit" v-model="row.aures_rs" :rules="{required : 1}" />

                      <div class="mt-2" v-if="row.aures_rs == 99999">
                        <label>Rumah Sakit Lainnya<small class="txt_mandatory">*</small></label>
                        <b-form-input v-model="row.aures_rs_lainnya" type="text" name="bahasa" id="bahasa"
                          class="form-control" placeholder="Rumah Sakit Lainnya" />

                        <VValidate name="Rumah Sakit Lainnya" v-model="row.aures_rs_lainnya"
                          :rules="toValidate(mrValidation.aures_rs_lainnya)" />
                      </div>

                    </div>

                    <div v-if="row.aures_asal_rujukan == 4" class="form-group col-md-4">
                      <label>Puskesmas <small class="txt_mandatory">*</small></label>

                      <div class="row">
                        <v-select class="col-md-8" :disabled="!row.aures_ap_id?true:false"
                          placeholder=" -- Pilih Puskesmas -- " v-model="row.aures_puskesmas" :options="mUGDPuskesmas"
                          label="text" :clearable="true" :reduce="v=>v.value">
                        </v-select>
                        <a href="javascript:;" @click="row.aures_puskesmas = 99999" class="btn-new col-md-4"><i
                            class="icon-plus2"></i> Tambah </a>
                      </div>

                      <VValidate name="Puskesmas" v-model="row.aures_puskesmas" :rules="{required : 1}" />


                      <div class="mt-2" v-if="row.aures_puskesmas == 99999">
                        <label>Puskesmas Lainnya<small class="txt_mandatory">*</small></label>
                        <b-form-input v-model="row.aures_puskesmas_lainnya" type="text" name="bahasa" id="bahasa"
                          class="form-control" placeholder="Puskesmas Lainnya" />

                        <VValidate name="Puskesmas Lainnya" v-model="row.aures_puskesmas_lainnya"
                          :rules="toValidate(mrValidation.aures_puskesmas_lainnya)" />
                      </div>


                    </div>
                  </div>



                  <h6 class="font-weight-semibold">Data Cara Bayar</h6>


                  <div class="row">
                    <div class="form-group col-md-4">
                      <label>Jaminan <small class="txt_mandatory">*</small></label>
                      <v-select :disabled="!row.aures_ap_id?true:false" @input="changeJaminan()"
                        placeholder=" -- Pilih Jaminan -- " v-model="row.aures_jaminan" :options="Config.mr.mJaminan"
                        label="text" :clearable="true" :reduce="v=>v.value">
                      </v-select>
                      <VValidate name="Jaminan" v-model="row.aures_jaminan"
                        :rules="toValidate(mrValidation.aures_jaminan)" />
                    </div>

                    <!--              
																		<div class="form-group col-md-4" v-if="row.aures_jaminan == 3">
																				<label>Tipe Rujukan</label>
																				<v-select @input="changeJaminan()" placeholder=" -- Pilih Tipe Rujukan -- " v-model="row.aures_type_rujukan"
																						:options="Config.mr.jenisRujukan" label="text" :clearable="true"
																						:reduce="v=>v.value">
																				</v-select>
																		</div>
																		-->

                    <template v-if="!errData">
                      <div class="col-12" v-if="row.aures_jaminan == 3">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label>Nomor Peserta BPJS <small class="txt_mandatory">*</small></label>
                            <b-form-input disabled v-model="row.aures_no_bpjs" :formatter="$parent.number" type="text"
                              name="bahasa" id="bahasa" class="form-control" placeholder="Nomor Peserta BPJS" />
                            <VValidate name="Nomor Peserta BPJS" v-model="row.aures_no_bpjs"
                              :rules="toValidate(mrValidation.aures_no_bpjs)" />
                          </div>

                          <!--
																				<div class="form-group col-md-5">
																						<label>Nomor Rujukan</label>
																						<v-select placeholder=" -- Pilih Rujukan -- " v-model="row.aures_no_rujukan"
																								:options="listRujukan" label="text" :clearable="true"
																								:reduce="v=>v.value"
																						>
																						</v-select>
																				</div>
																				-->


                          <div class="col-md-12">
                            <div class="table-responsive mb-1">
                              <table class="table table-bordered mb-0">
                                <thead>
                                  <tr>
                                    <th width="180">Keterangan </th>
                                    <th class="bg-primary">Data RS</th>
                                    <th class="bg-success">Data BPJS</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th class="table-secondary">
                                      <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">Nama Pasien</span>
                                      </div>
                                    </th>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p>{{row.ap_fullname||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p>{{row.apbd_fullname||"-"}}
                                          <span id="bpjs_status" class="badge badge-info">{{row.apbd_status}}</span>
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="table-secondary">
                                      <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">NIK KTP</span>
                                      </div>
                                    </th>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p>{{row.ap_nik||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p>{{row.apbd_nik||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="table-secondary">
                                      <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">Jenis Kelamin</span>
                                      </div>
                                    </th>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p>{{getConfigDynamic(Config.mr.StatusKelamin,row.ap_gender)||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p>{{getConfigDynamic(Config.mr.StatusKelamin,row.apbd_gender)||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="table-secondary">
                                      <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">Tanggal Lahir</span>
                                      </div>
                                    </th>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p v-if="row.ap_dob">{{ row.ap_dob | moment("DD MMMM YYYY")}}</p>
                                        <p v-else> - </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="wrap_preview_data">
                                        <p v-if="row.apbd_dob">{{ row.apbd_dob | moment("DD MMMM YYYY")}}</p>
                                        <p v-else> - </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <div class="form-group col-md-4" v-if="row.aures_jaminan == 2">
                      <label>Pilihan Asuransi <small class="txt_mandatory">*</small></label>
                      <v-select @input="row.aures_file_asuransi = null" :disabled="!row.aures_ap_id?true:false"
                        placeholder="Pilih Jenis Asuransi" v-model="row.aures_asuransi" :options="mAsuransi"
                        label="text" :clearable="true" :reduce="v=>v.value">
                      </v-select>
                      <VValidate name="Asuransi" v-model="row.aures_asuransi"
                        :rules="toValidate(mrValidation.aures_asuransi)" />

                    </div>

                    <div class="form-group col-md-4" v-if="row.aures_jaminan == 2">
                      <label>No Kartu Asuransi <small class="txt_mandatory">*</small></label>
                      <b-form-input v-model="row.aures_no_kartu_asuransi" :formatter="$parent.normalText" type="text"
                        class="form-control" />
                      <VValidate name="No. Kartu Asuransi" v-model="row.aures_no_kartu_asuransi"
                        :rules="{required: 1}" />
                    </div>

                    <div class="form-group col-md-4" v-if="row.aures_jaminan == 2">
                      <label>No Polis</label>
                      <b-form-input v-model="row.aures_no_polis" :formatter="$parent.normalText" type="text"
                        class="form-control" />
                    </div>

                    <template v-if="errData">
                      <div class="col-md-12">
                        <div class="alert alert-danger">
                          <h6 class="mb-0">{{errData||"-"}}</h6>
                        </div>
                      </div>
                    </template>


                    <template v-if="errDataV2">
                      <div class="col-md-12">
                        <div class="alert alert-danger">
                          <h6 class="mb-0">{{errDataV2||"-"}}</h6>
                        </div>
                      </div>
                    </template>

                  </div>


                  <div class="wrap_line_heading mb-4" v-if="!errData">
                    <h6 class="font-weight-semibold">Dokumen Pendukung</h6>
                    <p>Mohon gambar/foto yang di upload bisa terbaca dengan jelas</p>

                    <div class="form-row" id="docUmum" v-if="row.aures_jaminan == 1 || row.aures_jaminan == 2">
                      <div class="col-md-3" v-if="row.aures_jaminan == 1 || row.aures_jaminan == 2">
                        <div class="form-group">
                          <label for="">KTP/Kartu Keluarga</label>
                          <Uploader isDocument v-model="row.aures_file_ktp" type="docimage" />
                        </div>
                      </div>

                      <div class="col-md-3" v-if="row.aures_jaminan == 1 || row.aures_jaminan == 2">
                        <div class="form-group">
                          <label for="">Foto Pasien</label>
                          <Uploader isDocument v-model="row.aures_file_foto_pasien" type="image" />
                        </div>
                      </div>

                      <div class="col-md-3" v-if="row.aures_jaminan == 1 || row.aures_jaminan == 2">
                        <div class="form-group">
                          <label for="">Surat Rujukan</label>
                          <Uploader isDocument v-model="row.aures_surat_rujukan" type="docimage" />
                        </div>
                      </div>

                      <div class="col-md-3" v-if="row.aures_jaminan == 2">
                        <div class="form-group">
                          <label for="">Kartu Asuransi</label>
                          <Uploader isDocument v-model="row.aures_file_asuransi" type="docimage" />
                        </div>
                      </div>
                    </div>

                    <div class="form-row" v-if="row.aures_jaminan == 3">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Kartu BPJS</label>
                          <Uploader isDocument v-model="row.aures_kartu_bpjs" type="docimage" />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Surat Rujukan</label>
                          <Uploader isDocument v-model="row.aures_surat_rujukan" type="docimage" />

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3>Persetujuan Umum</h3>
                    <div class="form-group">
                      <label for="">Persetujuan dilakukan oleh <strong class="text-danger">*</strong></label>
                      <div>
                        <b-form-radio-group :options="Config.mr.statusPersetujuanPasien" v-model="row.aures_pu_pic" />
                        <VValidate name="Persetujuan Umum" v-model="row.aures_pu_pic"
                          :rules="toValidate(mrValidation.aures_pu_pic)" />
                      </div>
                    </div>
                    <div class="form-group agreement_content">
                      <h2>1. PERSETUJUAN UNTUK PERAWATAN DAN PENGOBATAN</h2>
                      <ol type="a">
                        <li>Saya menyetujui untuk perawatan di Rumah Sakit Harapan Magelang sebagai pasien rawat jalan
                          atau rawat inap tergantung kepada kebutuhan medis. Pengobatan dapat meliputi: X-Ray/Radiologi,
                          Electrocardiogram, Pemberian dan atau tindakan medis pemasangan infus serta penyuntikan
                          (intramuskular, intravena, dan prosedur invasif lainnya), Perawatan rutin, Pemberian produk
                          farmasi dan obat-obatan, Pemasangan alat kesehatan (kecuali yang membutuhkan persetujuan
                          khusus/tertulis), Pengambilan darah untuk pemeriksaan laboratorium atau patologi yang
                          dibutuhkan untuk pengobatan dan evaluasi (contohnya wawancara dan pemeriksaan fisik)</li>
                        <li>Saya sadar bahwa praktik kedokteran dan bedah bukanlah ilmu pasti dan saya mengakui bahwa
                          tidak ada jaminan atas hasil apapun, terhadap perawatan prosedur atau pemeriksaan apapun yang
                          dilakukan pada saya.</li>
                        <li>
                          Saya mengerti dan memahami bahwa:
                          <ol type="i">
                            <li>Saya memiliki hak untuk mengajukan pertanyaan tentang pengobatan yang diusulkan
                              (termasuk identitas setiap orang yang memberikan atau mengamati pengobatan) setiap
                              saat./li>
                            <li>Saya mengetahui bahwa persetujuan yang saya berikan tidak termasuk persetujuan untuk
                              prosedur / tindakan invasif (misalnya operasi) atau tindakan yang mempunyai resiko tinggi
                            </li>
                            <li>Saya mengetahui bahwa jika saya memutuskan untuk menghentikan perawatan medis untuk diri
                              saya sendiri, saya memahami bahwa Rumah Sakit Harapan dan Dokter tidak akan
                              bertanggungjawab atas hasil yang merugikan saya.</li>
                          </ol>
                        </li>
                      </ol>

                      <h2>2. BARANG PRIBADI</h2>
                      <p>Saya tidak diperkenankan untuk membawa barang-barang berharga ke ruang rawat inap, jika ada
                        anggota keluarga atau teman harus diminta untuk membawa pulang barang berharga tersebut.</p>
                      <p>Saya telah memahami bahwa rumah sakit tidak bertanggungjawab atas semua kehilangan
                        barang-barang milik saya dan saya secara pribadi bertanggungjawab atas barang-barang berharga
                        yang saya miliki, termasuk uang, perhiasan buku cek, kartu kredit, handphone atau barang
                        lainnya. Dan apabila saa membutuhkan maka saya dapat menitipkan barang pada tempat penitipan
                        barang di tempat resmi yang telah disediakan Rumah Sakit.</p>
                      <p>Saya juga mengerti bahwa saya harus memberitahu / menitipkan pada RS jika memiliki gigi palsu,
                        kacamata, lensa kontak, prosthetic atau barang lainnya yang saya butuhkan untuk diamankan.</p>

                      <h2>3. PERSETUJUAN PELEPASAN INFORMASI</h2>
                      <p>Saya memahami informasi yang ada didalam diri saya, termasuk diagnosis, hasil laboratorium, dan
                        hasil tes diagnostik yang akan digunakan untuk perawatan medis, Rumah Sakit Harapan akan
                        menjamin kerahasiaannya.</p>
                      <p>Saya memberi wewenang kepada Rumah Sakit untuk memberikan informasi tentang diagnosis, hasil
                        pelayanan dan pengobatan bila diperlukan untuk memproses klaim asuransi / Jamkesmas / Jamkesda /
                        perusahaan dan atau lembaga pemerintah.</p>
                      <p>Saya memberi wewenang kepada Rumah Sakit untuk memberikan informasi tentang diagnosis, hasil
                        pelayanan dan pengobatan saya kepada anggota keluarga saya kepada:</p>
                      <!--
                                    <ol type="a">
                                        <li>
                                            <b-form-input v-model="row.aures_pu_pelepasan_info_a" :formatter="$parent.normalText" type="text" class="form-control"/>
                                        </li>
                                        <li>
                                            <b-form-input v-model="row.aures_pu_pelepasan_info_b" :formatter="$parent.normalText" type="text" class="form-control"/>
                                        </li>
                                        <li>
                                            <b-form-input v-model="row.aures_pu_pelepasan_info_c" :formatter="$parent.normalText" type="text" class="form-control"/>
                                        </li>
                                    </ol>
                                    -->


                      <table class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th width="24">#</th>
                            <th>Nama Penerima Informasi</th>
                            <th>Hubungan Dengan Pasien</th>
                            <th>Nomor Telepon</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in (row.aures_penerima_info||[]).length" :key="k">
                            <td>{{k+1}}</td>

                            <td>
                              <b-form-input v-model="row.aures_penerima_info[k]['nama']" :formatter="$parent.normalText"
                                type="text" name="bahasa" id="bahasa" class="form-control"
                                placeholder="Nama Penerima informasi" />

                              <VValidate :name="'Nama Penerima informasi '+(k+1)"
                                v-model="row.aures_penerima_info[k]['nama']" :rules="{required : 1}" />
                            </td>

                            <td>
                              <b-form-input v-model="row.aures_penerima_info[k]['hub']" :formatter="$parent.normalText"
                                type="text" name="bahasa" id="bahasa" class="form-control"
                                placeholder="Hubungan Penerima informasi" />

                              <VValidate :name="'Hubungan Penerima informasi '+(k+1)"
                                v-model="row.aures_penerima_info[k]['hub']" :rules="{required : 1}" />
                            </td>

                            <td>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon2">+62</span>
                                </div>
                                <b-form-input v-model="row.aures_penerima_info[k]['tel']" :formatter="$parent.number"
                                  type="text" name="bahasa" id="bahasa" class="form-control" placeholder="No Telepon" />
                              </div>
                              <VValidate :name="'No Telepon Penerima informasi '+(k+1)"
                                v-model="row.aures_penerima_info[k]['tel']" :rules="{required : 1}" />
                            </td>

                            <td>
                              <a href="javascript:;" @click="(row.aures_penerima_info||[]).splice(k,1)"
                                class="btn btn-sm btn-icon border-danger alpha-danger text-danger rounded-circle"
                                data-popup="tooltip" title="Hapus">
                                <i class="icon-trash-alt"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="5" class="text-center">
                              <a href="javascript:;" @click="addPenerimaInfo()"
                                class="btn btn-info btn-sm btn-labeled btn-labeled-left">
                                <b><i class="icon-plus2"></i></b>
                                <span>Tambah</span>
                              </a>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <!--
                                    <h2>4. HAK DAN KEWAJIBAN PASIEN</h2>
                                    <p>Saya memiliki hak untuk mengambil bagian dalam keputusan mengenani penyakit saya dan dalam hal perawatan medis dan rencana pengobatan.</p>
                                    <p>Saya telah mendapat informasi tentang hak dan kewajiban pasien di Rumah Sakit Harapan Magelang melalui formulir dan banner yang disediakan oleh petugas.</p>
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="50%"><span class="text-uppercase">Hak - hak Pasien</span></th>
                                            <th width="50%"><span class="text-uppercase">Kewajiban Pasien</span></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td class="align-top">
                                            <ol class="pl-3">
                                                <li>Memperoleh informasi mengenai tata tertib dan peraturan yang berlaku di rumah sakit</li>
                                                <li>Memperoleh informasi terkait hak dan kewajiban pasien</li>
                                                <li>Memperoleh layanan yang manusiawi, adil, jujur dan tanpa diskriminasi</li>
                                                <li>Memperoleh layanan kesehatan yang bermutu sesuai dengan kebutuhan medis, standar profesi dan standar produsen operasional</li>
                                                <li>Memperoleh layanan yang efektif dan efisien sehingga pasien terhindar dari kerugian fisik dan materi</li>
                                                <li>Mengajukan pengaduan atas kualitas pelayanan yang didapatkan</li>
                                                <li>Memilih dokter dan kelas perawatan sesuai dengan keinginannya dna peraturan yang berlaku di rumah sakit</li>
                                                <li>Meminta konsultasi tentang penyakit yang dideritanya kepada dokter lain yang mempunyai Surat Izin Praktek (SIP) baik didalam maupun diluar rumah sakit</li>
                                                <li>Mendapatkan privasi dan kerahasiaan penyakit yang diderita termasuk data-data medisnya (isi rekam medis)</li>
                                                <li>Mendapat informasi yang meliputi diagnosis dan tata cara tindakan medis, tujuan tindakan medis, alternatif tindakan, resiko dan komplikasi yang mungkin terjadi dan prognosis terhadap tindakan yang dilakukan serta perkiraan biaya pengobatan/tindakan medis yang akan dilakukan terhadap dirinya</li>
                                                <li>Memberikan persetujuan atau penolakan sebagian atau seluruh tindakan yang akan diberikan oleh tenaga kesehatan terhadap penyakit yang dideritanya setelah menerima dan memahami informasi mengenai tindakan tersebut secara lengkap dengan pengecualian yang diatur dalam ketentuan peraturan perundang-undangan</li>
                                                <li>Didampingi keluarganya dan atau penasehatnya dalam keadaan kritis atau menjelang kematian</li>
                                                <li>Menjalankan ibadah sesuai agama atau kepercayaan yang dianutnya selama hal itu tidak mengganggu pasien lainnya</li>
                                                <li>Memperoleh keamanan dan keselamatan dirinya selama dalam perawatan di rumah sakit</li>
                                                <li>Mengajukan usul, saran, perbaikan atas perlakuan rumah sakit terhadap dirinya</li>
                                                <li>Menolak pelayanan bimbingan rohani yang tidak sesuai dengan agama dan kepercayaan yang dianutnya</li>
                                                <li>Menggugat dan/atau menuntut Rumah Sakit apabila Rumah Sakit diduga memberikan pelayanan yang tidak sesuai dengan standar baik secara perdata ataupun pidana; dan</li>
                                                <li>Mengeluhkan pelayanan Rumah Sakit yang tidak sesuai dengan standar pelayanan melalui media cetak dan elektronik sesuai dengan ketentuan peraturan perundang-undangan</li>
                                            </ol>
                                            </td>
                                            <td class="align-top">
                                            <ol class="pl-3">
                                                <li>Mematuhi peraturan yang berlaku di Rumah Sakit</li>
                                                <li>Menggunakan fasilitas Rumah Sakit secara bertanggungjawab</li>
                                                <li>Menghormati hak-hak pasien lain, pengunjung dan hak tenaga kesehatan serta petugas lainnya yang bekerja di Rumah Sakit</li>
                                                <li>Mmeberikan informasi yang jujur, lengkap dan akurat sesuai kemampuan dan pengetahuannya tentang masalah kesehatannya</li>
                                                <li>Memberikan informasi mengenai kemampuan finansial dan jaminan kesehatan yang dimilikinya</li>
                                                <li>Mematuhi rencana terapi yang direkomendasikan oleh tenaga kesehatan di Rumah Sakit dan disetujui oleh pasien yang bersangkutan setelah mendapatkan penjelasan sesuai ketentuan peraturan perundang-undangan</li>
                                                <li>Menerima segala konsekuensi atas keputusan pribadinya untuk menolak rencana terapi yang direkomendasikan oleh tenaga kesehatan dan/atau tidak mematuhi petunjuk yang diberikan oleh tenaga kesehatan dalam rangka penyembuhan penyakit atas masalah kesehatannya</li>
                                                <li>Memberikan imbalan jasa atas pelayanan yang diterima</li>
                                            </ol>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

																		<h2>5. INFORMASI RAWAT INAP</h2>
																		<p>Saya telah menerima informasi tentang peraturan yang diberlakukan oleh Rumah Sakit dan saya beserta keluarga bersedia untuk mematuhinya termasuk termasuk akan mematuhi jam berkunjung pasien sesuai dengan aturan di Rumah Sakit.</p>
																		<p>Anggota keluarga saya yang menunggu saya bersedia untuk selalu memakai tanda pengenal khusus yang diberikan oleh Rumah Sakit dan demi keamanan seluruh pasien setiap keluarga dan siapapun yang akan mengunjungi saya diluar jam berkunjung bersedia untuk dimintai/diperiksa identitasnya dan bersedia memakai tanda pengenal khusus yang diberikan oleh Rumah Sakit.</p>
																		<p>Saya mengetahui bahwa Rumah Sakit Harapan Magelang merupakan Rumah Sakit yang menjadi tempat praktek klinik bagi mahasiswa keperawatan dan profesi-profesi kesehatan lainnya. Karena itu mereka mungkin berpartisipasi dan atau terlibat dalam perawatan saya. Saya <strong>menyetujui / tidak menyetujui</strong> (coret salah satu) bahwa mahasiswa keperawatan dan profesi kesehatan lain berpartisipasi dalam perawatan saya, sepanjang dibawah supervise Dokter Penanggung Jawab Pelayanan.</p>
																		<div class="form-group">
																				<label for="">Partisipasi Mahasiswa dalam perawatan pasien <strong class="text-danger">*</strong></label>
																				<div>
																				<b-form-radio-group
																						:options="Config.mr.yesNoOpt"
																						v-model="row.aures_pu_info_ranap"
																				/>
																				<VValidate 
																						name="Partisipasi Mahasiswa dalam perawatan pasien" 
																						v-model="row.aures_pu_info_ranap" 
																						:rules="toValidate(mrValidation.aures_pu_info_ranap)"
																				/>
																				</div>
																		</div>

                                    <h2>6. PRIVASI</h2>
                                    <p>Saya <strong>mengizinkan/tidak mengizinkan</strong> Rumah Sakit memberi akses bagi: keluarga dan handai taulan serta orang-orang yang akan menengok saya (sebutkan nama bila ada permintaan khusus yang tidak diizinkan)</p>
                                    <div class="form-group">
                                        <label for="">Akses menengok pasien <strong class="text-danger">*</strong></label>
                                        <div>
                                        <b-form-radio-group
                                            :options="Config.mr.izinYesNo"
                                            v-model="row.aures_pu_privasi"
                                        />
                                        <VValidate 
                                            name="Akses menengok pasien" 
                                            v-model="row.aures_pu_privasi" 
                                            :rules="toValidate(mrValidation.aures_pu_privasi)"
                                        />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Nama yang tidak diizinkan (bila ada)</label>
                                        <textarea v-model="row.aures_pu_name" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                                    </div>
                                    -->

                      <h2>4. INFORMASI BIAYA</h2>
                      <p>Saya memahami tentang informasi biaya pengobatan atau biaya tindakan yang dijelaskan oleh
                        petugas Rumah Sakit.</p>
                    </div>
                  </div>

                  
                  <div class="wrap_line">
                    <h3>TTD General Consent</h3>
                    <div class="form-group">
                      <a href="javascript:;" v-if="row.aures_pu_pic" @click="openTTDData()" class="btn btn-primary">Tanda Tangani Dokumen</a>
                      <span v-else>Isi terlebih dahulu persetujuan umum</span>
                    </div>
                  </div>

                    
                </div>
              </div>
            </div>
            <div v-if="(row.aures_type == 'Q' && row.aures_is_draft != 'N')"
              :class="(row.aures_type == 'Q' && row.aures_is_draft != 'N') ? 'tab-pane fade show active card-body border-0 p-3' : 'tab-pane fade card-body border-0 p-3'"
              class="tab-pane fade card-body border-0 p-3" id="ugdQuickReservation">
              <div class="alert alert-info">
                <div class="d-flex">
                  <i class="icon-info22 mt-1"></i>
                  <span class="ml-2">Reservasi Cepat digunakan untuk identifikasi awal data pasien. Data bersifat
                    temporary, singkat dan akan diperbaharui/dilengkapi kemudian.</span>
                </div>
              </div>

              <div class="form-group" v-if="row.aures_is_draft != 'N'">
                <label for="">Opsi Pasien</label>
                <div>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label @click="changePasien('N')"
                      :class="row.aures_is_new_pasien == 'N' ? 'btn btn-outline-info alpha-info active' : 'btn btn-outline-info alpha-info'">
                      Pasien Lama
                    </label>

                    <label @click="changePasien('Y')"
                      :class="row.aures_is_new_pasien == 'Y' ? 'btn btn-outline-info alpha-info active' : 'btn btn-outline-info alpha-info'">
                      Pasien Baru
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="row.aures_is_new_pasien == 'N'" id="oldQuick" class="quickRegOpts">
                <div class="wrap_line_heading">
                  <div class="data_prev_cont">
                    <div class="form-row">

                      <div class="form-group col-md-12">
                        <div class="form-row">
                          <div class="col-md-8">
                            
                            <div class="row">
                              <div class="form-group col-md-5">
                                <b-form-input v-model="nama_pasien" type="text" name="bahasa" id="bahasa"
                                  class="form-control" placeholder="Nama Pasien" />
                              </div>
                              <div class="form-group col-md-5">
                                <b-form-input v-model="no_rm" type="text" name="bahasa" id="bahasa" class="form-control"
                                  placeholder="Nomor Rekam Medis" />
                              </div>
                              <div class="form-group col-md-2">
                                <a href="javascript:;" @click="searchPasien()" class="btn-new">Cari</a>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-5">
                                <b-form-input v-model="alamat" type="text" name="bahasa" id="bahasa" class="form-control"
                                  placeholder="Alamat" />
                              </div>

                              <div class="form-group col-md-5">
                                <b-form-input v-model="ttl" v-mask="'##/##/####'" type="text" name="bahasa" id="bahasa"
                                  class="form-control" placeholder="Tanggal Lahir" />
                              </div>
                              <div class="form-group col-md-2">
                                <a href="javascript:;" @click="resetPasien()" class="btn-new">Reset</a>
                              </div>

                            </div>
                            
                            <label>Nama Pasien <small class="txt_mandatory">*</small></label>
                            <v-select class="diagnosa-section select-paging" placeholder="-- Pilih Pasien -- "
                              v-model="row.aures_ap_id" :disabled="row.aures_is_draft != 'Y'"
                              @search="filterName($event)" @input="selectName($event)" :options="mPasien" label="text"
                              :clearable="true" :reduce="v=>v.value">

                              <template slot="selected-option">
                                <span v-b-tooltip.hover.right :title="row.ap_fullname">
                                  {{row.ap_fullname||"-"}} - {{row.ap_code||"-"}}
                                  <template v-if="row.ap_dob">
                                    -
                                    {{row.ap_dob | moment("DD MMMM YYYY")}}
                                  </template>
                                  <template v-else>
                                    -
                                    Tgl Lahir Kosong
                                  </template>
                                  - {{row.ap_dom_address||"Alamat Kosong"}}
                                </span>
                              </template>

                              <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover.right :title="option.text">
                                  {{ option.text }}
                                </span>
                              </template>

                              <template #list-footer v-if="(mPasien||[]).length >= 1">
                                <li class="list-paging">
                                  <div class="d-flex align-items-center justify-content-between">
                                    <b-button @click="changePage(mPasien[0].currentPage,'min')" size="sm"
                                      variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{mPasien[0].currentPage}} dari
                                      {{ceilData(mPasien[0].totalRows/10)}}</small>
                                    <b-button @click="changePage(mPasien[0].currentPage,'plus')" size="sm"
                                      variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-right22 text-white"></i></b-button>
                                  </div>
                                </li>
                              </template>
                            </v-select>

                            <VValidate name="Nama Pasien" v-model="row.aures_ap_id"
                              :rules="toValidate(mrValidation.aures_ap_id)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="Object.keys(rowPasien||{}).length && rowPasien.ap_code">
                      <div class="card">
                        <div class="card-body bg_card_blue">
                          <div class="row">
                            <div class="col-md-9">
                              <table class="table table-borderless table-sm text-uppercase">
                                <tbody>
                                  <tr>
                                    <td width="33%">
                                      <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{rowPasien.ap_code||"-"}}</p>
                                      </div>
                                    </td>
                                    <td width="33%">
                                      <div class="result_tab">
                                        <h4>Nama Pasien</h4>
                                        <p>{{rowPasien.ap_fullname||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="33%">
                                      <div class="result_tab">
                                        <h4>Tempat, Tanggal Lahir </h4>
                                        <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Usia</h4>
                                        <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{rowPasien.cg_label||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Alamat</h4>
                                        <p>{{rowPasien.ap_dom_address||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else-if="row.aures_is_new_pasien == 'Y'" id="newQuick" class="quickRegOpts">
                <div class="wrap_line_heading">
                  <h6 class="font-weight-semibold">Informasi Pasien</h6>
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>Kewarganegaraan</label>
                      <v-select placeholder="Pilih Kewarganegaraan" v-model="rowPasien.ap_kewarganegaraan"
                        :options="Config.mr.statusKewarganegaraan" label="text" :clearable="true" :reduce="v=>v.value">
                      </v-select>
                    </div>

                    <div class="form-group col-lg-4">
                      <label>Nama Lengkap <small class="txt_mandatory">*</small></label>
                      <b-form-input @input="autoFillPJKI($event)" v-model="rowPasien.ap_fullname"
                        :formatter="$parent.normalText" type="text" name="namaLengkap" id="namaLengkap"
                        class="form-control" placeholder="Nama Lengkap" />
                      <VValidate name="Nama Lengkap" v-model="rowPasien.ap_fullname"
                        :rules="toValidate(mrValidation.ap_fullname)" />
                    </div>

                    <div class="form-group col-md-3">
                      <label>No. KTP/KITAS <small class="text-muted"></small> </label>
                      <b-form-input v-model="rowPasien.ap_nik" :formatter="$parent.number" type="text" name="noKTP"
                        id="noKTP" class="form-control" placeholder="No. KTP/KITAS" />
                      <small class="text-info">NIK Sementara</small>
                    </div>

                    <div class="w-100"></div>
                    <div class="form-group col-md-3">
                      <label>Jenis Kelamin<small class="txt_mandatory">*</small></label>
                      <v-select placeholder="Pilih Jenis Kelamin" v-model="rowPasien.ap_gender"
                        :options="Config.mr.StatusKelamin" label="text" :clearable="true" :reduce="v=>v.value">
                      </v-select>
                      <VValidate name="Jenis Kelamin" v-model="rowPasien.ap_gender"
                        :rules="toValidate(mrValidation.ap_gender)" />
                    </div>

                    <div class="form-group col-md-3">
                      <label>Tanggal Lahir</label>
                      <div class="input-group">
                        <input type="text" @input="checkValidDate($event)" placeholder="DD/MM/YYYY" class="form-control"
                          v-mask="'##/##/####'" v-model="rowPasien.ap_dob">
                        <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label>Golongan Darah</label>
                      <v-select placeholder="Pilih Golongan Darah" v-model="rowPasien.ap_gol_darah"
                        :options="Config.mr.golDarah" label="text" :clearable="true" :reduce="v=>v.value">
                      </v-select>
                    </div>
                  </div>
                </div>
                <div class="wrap_line_heading">
                  <h6 class="font-weight-semibold">Data Penanggung Jawab</h6>
                  <div class="form-group">
                    <div class="form-check align-middle">
                      <label class="form-check-label">
                        <b-form-checkbox v-model="rowPasien.arpj_is_same" value="Y" unchecked-value="N"
                          class="form-check-input-styled p-0" name="edukasi">Penanggungjawab adalah pasien
                        </b-form-checkbox>
                      </label>
                    </div>
                  </div>
                  <div class="row">

                    <div class="form-group col-md-3">
                      <label>Hubungan Keluarga <small class="txt_mandatory">*</small></label>
                      <v-select placeholder="Pilih Hubungan Keluarga" :disabled="rowPasien.arpj_is_same == 'Y'"
                        v-model="rowPasien.arpj_hubungan_keluarga" :options="mHubunganKeluarga" label="text"
                        :clearable="true" :reduce="v=>v.value">
                      </v-select>
                      <VValidate name="Hubungan Keluarga Penanggung Jawab" v-model="rowPasien.arpj_hubungan_keluarga"
                        :rules="toValidate(mrValidation.arpj_hubungan_keluarga)" />
                    </div>
                    <div class="form-group col-md-3" v-if="rowPasien.arpj_hubungan_keluarga == 6">
                      <label>Hubungan Keluarga Lainnya <small class="text-muted"></small> <small
                          class="txt_mandatory">*</small></label>
                      <b-form-input :disabled="rowPasien.arpj_is_same == 'Y'"
                        v-model="rowPasien.arpj_hubungan_keluarga_text" :formatter="$parent.normalText" type="text"
                        name="bahasa" id="bahasa" class="form-control" placeholder="Hubungan Keluarga Lainnya" />
                      <VValidate name="Hubungan Keluarga Lainnya" v-model="rowPasien.arpj_hubungan_keluarga_text"
                        :rules="toValidate(mrValidation.arpj_hubungan_keluarga_text)" />
                    </div>


                    <div class="form-group col-md-4">
                      <label>Nama Penanggung Jawab <small class="txt_mandatory">*</small></label>
                      <b-form-input @input="autoFillPJKI(e)" :disabled="rowPasien.arpj_is_same == 'Y'"
                        v-model="rowPasien.arpj_fullname" :formatter="$parent.normalText" type="text" name="namaLengkap"
                        id="namaLengkap" class="form-control" placeholder="Nama Penanggung Jawab" />

                      <VValidate name="Nama Penanggung Jawab" v-model="rowPasien.arpj_fullname"
                        :rules="toValidate(mrValidation.arpj_fullname)" />
                    </div>

                    <div class="form-group col-md-4">
                      <label>No Handphone</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon2">+62</span>
                        </div>
                        <b-form-input :disabled="rowPasien.arpj_is_same == 'Y'" v-model="rowPasien.arpj_phone_number"
                          :formatter="$parent.number" type="text" name="bahasa" id="bahasa" class="form-control"
                          placeholder="No Telepon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="wrap_line_heading">
                  <h6 class="font-weight-semibold">Memberi kewenangan informasi kesehatan pasien kepada</h6>
                  <div class="row">
                    <div class="form-group col-12 mb-4">
                      <div class="form-check align-middle">
                        <label class="form-check-label">
                          <b-form-checkbox v-model="rowPasien.arki_is_same" value="Y" unchecked-value="N"
                            class="form-check-input-styled p-0" name="edukasi">Penanggungjawab adalah pasien
                          </b-form-checkbox>
                        </label>
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label>Hubungan Keluarga <small class="txt_mandatory">*</small></label>
                      <v-select placeholder="Pilih Hubungan Keluarga" :disabled="rowPasien.arki_is_same == 'Y'"
                        v-model="rowPasien.arki_hubungan_keluarga" :options="mHubunganKeluarga" label="text"
                        :clearable="true" :reduce="v=>v.value">
                      </v-select>
                      <VValidate name="Hubungan Keluarga Kewenangan Informasi"
                        v-model="rowPasien.arki_hubungan_keluarga"
                        :rules="toValidate(mrValidation.arki_hubungan_keluarga)" />
                    </div>
                    <div class="form-group col-md-3" v-if="rowPasien.arki_hubungan_keluarga == 6">
                      <label>Hubungan Keluarga Lainnya <small class="text-muted"></small> <small
                          class="txt_mandatory">*</small></label>
                      <b-form-input :disabled="rowPasien.arki_is_same == 'Y'"
                        v-model="rowPasien.arki_hubungan_keluarga_text" :formatter="$parent.normalText" type="text"
                        name="bahasa" id="bahasa" class="form-control" placeholder="Hubungan Keluarga Lainnya" />
                      <VValidate name="Hubungan Keluarga Lainnya" v-model="rowPasien.arki_hubungan_keluarga_text"
                        :rules="toValidate(mrValidation.arki_hubungan_keluarga_text)" />
                    </div>

                    <div class="form-group col-md-4">
                      <label>Nama Penerima Kewenangan <small class="txt_mandatory">*</small></label>
                      <b-form-input :disabled="rowPasien.arki_is_same == 'Y'" v-model="rowPasien.arki_fullname"
                        :formatter="$parent.normalText" type="text" name="namaLengkap" id="namaLengkap"
                        class="form-control" placeholder="Nama Penerima Kewenangan" />

                      <VValidate name="Nama Penerima Kewenangan" v-model="rowPasien.arki_fullname"
                        :rules="toValidate(mrValidation.arki_fullname)" />
                    </div>

                    <div class="form-group col-md-4">
                      <label>No Handphone</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon2">+62</span>
                        </div>
                        <b-form-input :disabled="rowPasien.arki_is_same == 'Y'" v-model="rowPasien.arki_phone_number"
                          :formatter="$parent.number" type="text" name="bahasa" id="bahasa" class="form-control"
                          placeholder="No Telepon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wrap_line_heading">
                <h6 class="font-weight-semibold">Data Cara Bayar</h6>
                <div class="row">
                  <div class="form-group col-md-4">
                    <label>Jaminan <small class="txt_mandatory">*</small></label>
                    <v-select :disabled="true" @input="changeJaminan()" placeholder=" -- Pilih Jaminan -- "
                      v-model="row.aures_jaminan" :options="Config.mr.mJaminan" label="text" :clearable="true"
                      :reduce="v=>v.value">
                    </v-select>
                    <VValidate name="Jaminan" v-model="row.aures_jaminan"
                      :rules="toValidate(mrValidation.aures_jaminan)" />
                    <div class="form-text text-info-800">
                      <i class="icon-info22 align-middle"></i>
                      <span class="ml-1 align-middle">Data ini dapat diperbaharui kemudian</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
              <button type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>


    <validation-observer ref="VFormGenerateRM">
      <b-modal v-model="modalGenerate" :title="'Generate Nomor RM'" size="md" @ok.prevent="generateNomorRM">
        <div class="modal-body p-0">
          <div class="row mb-2">
            <div class="col-md-12">
              <b-form-group class="mb-0">

                <label for="">Masukkan Nomor Rekam Medis<span class="text-danger">*</span></label>
                <b-form-input :formatter="$parent.numberOnly" placeholder="Nomor Rekam Medis" type="text"
                  class="form-control" v-model="nomorGenerate" />

                <VValidate name="Nomor Rekam Medis" v-model="nomorGenerate" :rules="{required: 1, min: 15, max: 15}" />

              </b-form-group>
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>


    <validation-observer ref="VFormTTD">
      <b-modal v-model="ttdOpen" :title="'Tanda Tangani Dokumen'" ok-only size="xl" @ok.prevent="doSubmitTTD">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                <label for="">Tanda Tangan Petugas</label>
                <div class="signing-element" data-target-id="ttdPetugas">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPetugas"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPetugas','aures_petugas')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPetugas','aures_petugas')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>

                <div class="form-group">
                <label for="">Tanda Pembuat Pernyataan</label>
                <div class="signing-element" data-target-id="ttdPembuatPernyataan">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPembuatPernyataan"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPembuatPernyataan','aures_pembuat_pernyataan')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPembuatPernyataan','aures_pembuat_pernyataan')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>
                
                <template v-if="row.aures_pu_pic == 'W'">
                <div class="form-group">
                    <label for="">Nama Pembuat Pernyataan<strong class="text-danger">*</strong></label>
                    <b-form-input type="text" class="form-control" v-model="row.aures_pj_name" placeholder="e.g Bambang" />
                    
                    <VValidate 
                      name="Nama Pembuat Pernyataan" 
                      v-model="row.aures_pj_name" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                </div>
                
                <div class="form-group">
                  <label for="">Tanggal Lahir<strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <div class="input-group mb-3">
                      <datepicker 
                        input-class="form-control transparent"
                        placeholder="Tanggal Lahir" class="my-datepicker"
                        calendar-class="my-datepicker_calendar"
                        v-model="row.aures_pj_dob"
                        >
                      </datepicker>
                      <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i
                            class="icon-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                  <VValidate 
                    name="Tanggal Lahir" 
                    v-model="row.aures_pj_dob" 
                    :rules="{required: 1}"
                />
                </div>

                <div class="form-group">
                  <label for="">Address<strong class="text-danger">*</strong></label>
                  <textarea v-model="row.aures_pj_address" name="ftNote" id="ftNote" rows="4" class="form-control"
                  ></textarea>
                  <VValidate 
                    name="Address" 
                    v-model="row.aures_pj_address" 
                    :rules="{required: 1}"
                />
                </div>
                
                <div class="form-group">
                  <label for="">No Telepon<strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon2">+62</span>
                    </div>
                    <b-form-input v-model="row.aures_pj_no_tep"
                      :formatter="$parent.number" type="text" name="bahasa" id="bahasa" class="form-control"
                      placeholder="No Telepon" />
                  </div>
                  <VValidate 
                    name="No Telepon" 
                    v-model="row.aures_pj_no_tep" 
                    :rules="{required: 1}"
                />
                </div>


                </template>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                <div class="df-content">
                    <!-- Copas Template dari sini -->
                    <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;text-align: justify;">
                      <div>
                        <h2 style="text-align: center;margin-bottom: 0px;font-size: 18px;">PERSETUJUAN UMUM<br>RUMAH SAKIT HARAPAN MAGELANG</h2>

                        <h3 style="font-size: 16px;">IDENTITAS PASIEN</h3>
                        <table style="font-size: 12px;">
                          <tbody><tr>
                            <td>Nama Pasien</td>
                            <td style="padding-left: 8px;">: <span>{{rowPasien.ap_fullname||"-"}}</span></td>
                          </tr>
                          <tr>
                            <td>Nomor Rekam Medis</td>
                            <td style="padding-left: 8px;">: {{rowPasien.ap_code||"-"}}</td>
                          </tr>
                          <tr>
                            <td>Tanggal Lahir</td>
                            <td style="padding-left: 8px;">: {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</td>
                          </tr>
                          <tr>
                            <td>Alamat</td>
                            <td style="padding-left: 8px;">: {{rowPasien.ap_address||"-"}}</td>
                          </tr>
                          <tr>
                            <td>No. Telp</td>
                            <td style="padding-left: 8px;">: {{rowPasien.ap_phone_number||"-"}}</td>
                          </tr>
                        </tbody></table>

                        <h3 style="font-size: 16px;">PASIEN DAN / ATAU WALI HUKUM HARUS MEMBACA, MEMAHAMI DAN MENGISI INFORMASI BERIKUT</h3>
                        <p>Yang bertandatangan dibawah ini:</p>
                        <table style="font-size: 12px;">
                          <tbody><tr>
                            <td>Nama</td>
                            <td style="padding-left: 8px;">: 
                            <span v-if="row.aures_pu_pic == 'P'">{{rowPasien.ap_fullname||"-"}}</span>
                            <span v-else>{{row.aures_pj_name||"-"}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Tanggal Lahir</td>
                            <td style="padding-left: 8px;">: 
                            <span v-if="row.aures_pu_pic == 'P'">
                              <template v-if="rowPasien.ap_dob">                            
                                {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}
                              </template>
                              <template v-else>
                                -
                              </template>
                            </span>
                            <span v-else>
                              <template v-if="row.aures_pj_dob">
                                {{row.aures_pj_dob | moment("DD MMMM YYYY")}}
                              </template>
                              <template v-else>
                                -
                              </template>
                            </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Alamat</td>
                            <td style="padding-left: 8px;">: 
                              <span v-if="row.aures_pu_pic == 'P'">{{rowPasien.ap_address||"-"}}</span>
                              <span v-else>{{row.aures_pj_address||"-"}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>No. Telp</td>
                            <td style="padding-left: 8px;">: 
                              <span v-if="row.aures_pu_pic == 'P'">{{rowPasien.ap_phone_number||"-"}}</span>
                              <span v-else>{{row.aures_pj_no_tep||"-"}}</span>
                            </td>
                          </tr>
                        </tbody></table>

                        <p style="margin-bottom: 0;">Selaku pasien RS Harapan Magelang dengan ini menyatakan persetujuan:</p>

                        <h3 style="font-size: 16px;">1. PERSETUJUAN UNTUK PERAWATAN DAN PENGOBATAN</h3>
                        <ol type="a">
                          <li>Saya menyetujui untuk perawatan di Rumah Sakit Harapan Magelang sebagai pasien rawat jalan atau rawat inap
                            tergantung kepada kebutuhan medis. Pengobatan dapat meliputi: X-Ray/Radiologi, Electrocardiogram, Pemberian
                            dan atau tindakan medis pemasangan infus serta penyuntikan (intramuskular, intravena, dan prosedur invasif
                            lainnya), Perawatan rutin, Pemberian produk farmasi dan obat-obatan, Pemasangan alat kesehatan (kecuali yang
                            membutuhkan persetujuan khusus/tertulis), Pengambilan darah untuk pemeriksaan laboratorium atau patologi
                            yang dibutuhkan untuk pengobatan dan evaluasi (contohnya wawancara dan pemeriksaan fisik)</li>
                          <li>Saya sadar bahwa praktik kedokteran dan bedah bukanlah ilmu pasti dan saya mengakui bahwa tidak ada
                            jaminan atas hasil apapun, terhadap perawatan prosedur atau pemeriksaan apapun yang dilakukan pada saya.
                          </li>
                          <li>
                            Saya mengerti dan memahami bahwa:
                            <ol type="i">
                              <li>Saya memiliki hak untuk mengajukan pertanyaan tentang pengobatan yang diusulkan (termasuk identitas
                                setiap orang yang memberikan atau mengamati pengobatan) setiap saat.</li>
                              <li>Saya mengetahui bahwa persetujuan yang saya berikan tidak termasuk persetujuan untuk prosedur /
                                tindakan invasif (misalnya operasi) atau tindakan yang mempunyai resiko tinggi</li>
                              <li>Saya mengetahui bahwa jika saya memutuskan untuk menghentikan perawatan medis untuk diri saya sendiri,
                                saya memahami bahwa Rumah Sakit Harapan dan Dokter tidak akan bertanggungjawab atas hasil yang merugikan
                                saya.</li>
                            </ol>
                          </li>
                        </ol>

                        <h3 style="font-size: 16px;">2. BARANG PRIBADI</h3>
                        <p>Saya tidak diperkenankan untuk membawa barang-barang berharga ke ruang rawat inap, jika ada anggota keluarga
                          atau teman harus diminta untuk membawa pulang barang berharga tersebut.</p>
                        <p>Saya telah memahami bahwa rumah sakit tidak bertanggungjawab atas semua kehilangan barang-barang milik saya
                          dan saya secara pribadi bertanggungjawab atas barang-barang berharga yang saya miliki, termasuk uang,
                          perhiasan buku cek, kartu kredit, handphone atau barang lainnya. Dan apabila saa membutuhkan maka saya dapat
                          menitipkan barang pada tempat penitipan barang di tempat resmi yang telah disediakan Rumah Sakit.</p>
                        <p>Saya juga mengerti bahwa saya harus memberitahu / menitipkan pada RS jika memiliki gigi palsu, kacamata,
                          lensa kontak, prosthetic atau barang lainnya yang saya butuhkan untuk diamankan.</p>

                        <h3 style="font-size: 16px;">3. PERSETUJUAN PELEPASAN INFORMASI</h3>
                        <p>Saya memahami informasi yang ada didalam diri saya, termasuk diagnosis, hasil laboratorium, dan
                          hasil tes diagnostik yang akan digunakan untuk perawatan medis, Rumah Sakit Harapan akan
                          menjamin kerahasiaannya.</p>
                        <p>Saya memberi wewenang kepada Rumah Sakit untuk memberikan informasi tentang diagnosis, hasil
                          pelayanan dan pengobatan bila diperlukan untuk memproses klaim asuransi / Jamkesmas / Jamkesda /
                          perusahaan dan atau lembaga pemerintah.</p>
                        <p>Saya memberi wewenang kepada Rumah Sakit untuk memberikan informasi tentang diagnosis, hasil
                          pelayanan dan pengobatan saya kepada anggota keluarga saya kepada:</p>
                        
                        <template v-if="wewenang">
                          <p>{{wewenang||"-"}}</p>
                        </template>

                        <h3 style="font-size: 16px;">4. INFORMASI BIAYA</h3>
                        <p>Saya memahami tentang informasi biaya pengobatan atau biaya tindakan yang dijelaskan oleh
                        petugas Rumah Sakit.</p>

                        <h3 style="text-align: center;text-transform: uppercase;">TANDA TANGAN</h3>
                        <p>Dengan tanda tangan di bawah, saya menyatakan bahawa saya telah membaca dan memahami item pada Persetujuan Umum/General Consent.</p>
                        <table width="100%" style="font-size: 12px;text-align: center;">
                          <tbody><tr>
                            <td colspan="3">
                              <div style="margin-bottom: 8px;">Magelang, {{now}}</div>
                            </td>
                          </tr>
                          <tr>
                            <td width="36%" style="vertical-align: top;">
                              <div>Petugas</div>
                              <span>
                              <img :src="row.aures_petugas ? row.aures_petugas: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPetugas">
                              <br/>
                              </span>                       

                              <div>( <span>{{$parent.user.fullName||"-"}}}</span> )</div>
                            </td>
                            <td></td>
                            <td width="36%" style="vertical-align: top;">
                              <div>Pembuat Pernyataan</div>
                              <div style="margin: 8px 0;">
                              <img :src="row.aures_pembuat_pernyataan ? row.aures_pembuat_pernyataan: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPembuatPernyataan">
                              </div>
                              <div>
                                <span style="text-decoration: underline;">
                                {{row.aures_pu_pic == 'P' ? rowPasien.ap_fullname : row.aures_pj_name }}
                                </span>
                                <br>
                                <span>(Wali jika pasien &lt; 18 tahun)</span>
                              </div>
                            </td>
                          </tr>
                        </tbody></table>
                      </div>
                    </div>
                    <!-- End Copas Template -->
                </div>
                </div>
            </div>
        </div>
      </b-modal>
    </validation-observer>
  </div>
</template>


<script>
  import $ from 'jquery'
  const _ = global._
  import Config from '@/libs/Config'
  import Gen from '@/libs/Gen.js'
  const moment = require('moment')
  import Datepicker from 'vuejs-datepicker'

  export default {
    props: {
      row: Object,
      mrValidation: Object,
      rowPasien: Object,
    },
    data() {
      return {
        searchData: null,
        errData: "",
        errDataV2: "",
        modalGenerate: false,
        nomorGenerate: null,

        mPasien: [],
        mAsuransi: [],
        listRujukan: [],

        mDokterUGD: [],
        mUGDPuskesmas: [],
        mUGDRS: [],
        mHubunganKeluarga: [],

        isBPJS: true,
        isPasien: 'N',

        nama_pasien: null,
        no_rm: null,
        alamat: null,
        ttl: null,

        ttdOpen: false

      }
    },
    mounted() {
      setTimeout(() => {
        this.apiGetMaster()
        this.changeJaminan(true)
      }, 1500)
    },
    components: {
      Datepicker
    },
    computed: {
      Config() {
        return Config
      },
      now(){
          return moment().format('DD MMM YYYY, HH:mm')
      },
      wewenang(){
          let datawewenang = []
          for(let i = 0; i < (this.row.aures_penerima_info||[]).length; i++){
              datawewenang.push(
                  this.row.aures_penerima_info[i].nama + " (" +this.row.aures_penerima_info[i].hub +"," + this.row.aures_penerima_info[i].tel + ")"
              )
          }
          return (datawewenang).join(", ")
      },
    },
    methods: {
      doSubmitTTD(){
        this.$refs['VFormTTD'].validate().then(success => {
          if (success) {
            this.row.aures_template = $(".document-framer").html()
            this.ttdOpen = false      
          }
        })
      },
      openTTDData(){
        this.ttdOpen = true
        setTimeout(()=>{
            $(".sign-container canvas").attr("width",400)
            $(".sign-container canvas").attr("height",160)     
            console.log($(".sign-container canvas"))           
            this.$nextTick(() => {
              
              if(this.row.aures_petugas){
                if(this.$refs['ttdPetugas']){
                    this.$refs['ttdPetugas'].fromDataURL(this.row.aures_petugas)
                }
              }

              if(this.row.aures_pembuat_pernyataan){
                if(this.$refs['ttdPembuatPernyataan']){
                    this.$refs['ttdPembuatPernyataan'].fromDataURL(this.row.aures_pembuat_pernyataan)
                }
              }

              if(!this.row.aures_pj_name){
                this.row.aures_pj_name = this.rowPasien.arpj_fullname
              }
              if(!this.row.aures_pj_dob){
                this.row.aures_pj_dob = null
              }
              if(!this.row.aures_pj_address){
                this.row.aures_pj_address = this.rowPasien.arpj_address
              }
              if(!this.row.aures_pj_no_tep){
                this.row.aures_pj_no_tep = this.rowPasien.arpj_phone_number              
              }
            })
        },200)
      },
      resetPasien() {
        this.nama_pasien = null
        this.no_rm = null
        this.alamat = null
        this.ttl = null
      },
      searchPasien() {

        let dateTTL = null
        if (this.ttl) {
          if (this.ttl.length == 10) {
            let dobdata = this.ttl.split("/")
            let date = moment(dobdata[2] + "-" + dobdata[1] + "-" + dobdata[0]).format('DD/MM/YYYY')
            let d = new Date()
            if (date == 'Invalid date' || dobdata[2] > d.getFullYear()) {
              return this.$swal({
                icon: 'error',
                title: 'Tanggal Tidak Valid'
              })
            }

            dateTTL = dobdata[2] + "-" + dobdata[1] + "-" + dobdata[0]
          } else {
            return this.$swal({
              icon: 'error',
              title: 'Tanggal Tidak Valid'
            })
          }
        }

        this.$parent.loadingOverlay = true
        Gen.apiRest('/do/' + 'UGDReservasi', {
          data: {
            type: 'select-paging-pasien',
            nama_pasien: this.nama_pasien,
            no_rm: this.no_rm,
            alamat: this.alamat,
            ttl: dateTTL,
          }
        }, 'POST').then(res => {
          this.row.aures_ap_id = null
          this.mPasien = res.data.data
          this.$parent.loadingOverlay = false
        })
      },
      addPenerimaInfo() {
        this.row.aures_penerima_info.push({
          'nama': null,
          'hub': null,
          'tel': null,
        })
      },
      autoFillPJKI(e) {
        if (this.rowPasien.arpj_is_same == "Y") {
          this.rowPasien.arpj_fullname = e
        }
        if (this.rowPasien.arki_is_same == "Y") {
          this.rowPasien.arki_fullname = this.rowPasien.arpj_fullname
        }
      },
      checkValidDate(e) {
        let dob = e.target.value
        if (dob.length == 10) {
          let dobdata = dob.split("/")
          let date = moment(dobdata[2] + "-" + dobdata[1] + "-" + dobdata[0]).format('DD/MM/YYYY')
          let d = new Date()
          if (date == 'Invalid date' || dobdata[2] > d.getFullYear()) {
            return this.$swal({
              icon: 'error',
              title: 'Tanggal Tidak Valid'
            })
          }
        }
      },
      generateNomorRM() {
        this.$refs['VFormGenerateRM'].validate().then(success => {
          if (success) {
            let data = {
              nomorRM: this.nomorGenerate,
              type: 'generate-nomor-rm',
              aures_id: this.row.aures_id
            }
            this.$parent.loadingOverlay = true

            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: data
              }, "POST"
            ).then(res => {
              this.$parent.loadingOverlay = false
              let resp = res.data
              resp.statusType = 200
              this.$swal({
                title: resp.message,
                icon: resp.status,
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(result => {
                if (result.value) {
                  this.nomorGenerate = null
                  this.modalGenerate = false
                  this.$parent.apiGet()
                }
              })
            }).catch(err => {
              this.$parent.loadingOverlay = false
              if (err) {
                err.statusType = err.status
                err.status = "error"
                err.title = err.response ?.data ?.title
                err.message = err.response ?.data ?.message
                err.messageError = err.message
              }
              this.$parent.doSetAlertForm(err)
            })
          }
        })
      },
      back() {
        this.$router.back()
      },
      doSubmit() {
        this.$refs['VForm'].validate().then(success => {
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }
                }, 500)
              }
            })
          }


          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.row
                data.dataPasien = this.rowPasien
                data.type = 'submit-data'
                data.isNeedData = this.row.aures_is_draft == "Y" ? null : true

                this.$parent.loadingOverlay = true
                Gen.apiRest(
                  "/do/" + 'UGDReservasi', {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.$parent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    if (result.value) {
                      this.$router.push({
                          name: 'UGDReservasi',
                          params: {
                            pageSlug: this.$route.params.pageSlug
                          },
                          query: {
                            isDetail: true
                          }
                        })
                        .catch(() => {})
                    }
                  })
                }).catch(err => {
                  this.$parent.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response ?.data ?.title
                    err.message = err.response ?.data ?.message
                    err.messageError = err.message
                  }
                  this.$parent.doSetAlertForm(err)
                })

              }
            })
          }

        })
      },
      changeTab(val) {
        this.row.aures_type = val
        this.row.aures_jaminan = 1
        this.resetPasien()
      },
      changePasien(val) {
        console.log(val)
        if (val != this.row.aures_is_new_pasien) {
          if (val == "Y") {
            this.row.aures_ap_id = null
            this.rowPasien = null

            let data = {}
            data.type = 'init-new-pasien'

            this.$parent.loadingOverlay = true
            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: data
              },
              "POST"
            ).then(res => {
              this.rowPasien = res.data
              console.log(res.data.ap_id)

              this.row.aures_ap_id = res.data.ap_id
              this.row.aures_is_new_pasien = val
              this.$parent.loadingOverlay = false
            })
          } else {
            let data = {}
            data.type = 'delete-pasien'
            data.id = this.row.aures_ap_id
            console.log(this.row.aures_ap_id)
            if (this.row.aures_ap_id) {
              data.is_draft = this.rowPasien.ap_is_draft == 'Y' ? true : false
              this.$parent.loadingOverlay = true
              Gen.apiRest(
                "/do/" + this.$parent.modulePage, {
                  data: data
                },
                "POST"
              ).then(res => {
                this.row.aures_is_new_pasien = val
                this.$parent.loadingOverlay = false

                this.row.aures_ap_id = null
                this.rowPasien = null

              })
            } else {
              this.row.aures_ap_id = null
              this.rowPasien = null

              this.row.aures_is_new_pasien = val
            }
          }
        }
        // setTimeout(()=>{
        //     this.$parent.apiGet()
        // },250)
      },
      autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if (!data.aur_reg_code) {
          Gen.apiRest(
            "/do/" + this.$parent.modulePage, {
              data: data
            },
            "POST"
          )
        }
      }, 1000),

      autoSavePasien: _.debounce(function (data) {
        if (this.row.aures_is_new_pasien == "Y" && this.row.aures_ap_id) {
          data.type = 'auto-save-pasien'
          if (!data.aur_reg_code) {
            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: data
              },
              "POST"
            )
          }
        }
      }, 1000),

      addNewPasien() {
        let data = {
          type: 'init-new-pasien'
        }
        this.$parent.loadingOverlay = true
        Gen.apiRest(
          "/do/" + 'RoPasien', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.loadingOverlay = false
          let data = res.data.data
          this.$router.push({
            name: 'RoPasien',
            params: {
              pageSlug: data.ap_id
            },
            query: {
              isUGD: true
            }
          }).catch(() => {})
        })
      },

      filterName: _.debounce(function (e) {
        this.searchData = e
        Gen.apiRest('/do/' + 'UGDReservasi', {
          data: {
            type: 'select-paging-pasien',
            search: e,
            nama_pasien: this.nama_pasien,
            no_rm: this.no_rm,
            alamat: this.alamat
          }
        }, 'POST').then(res => {
          this.mPasien = res.data.data
        })
      }, 10),
      selectName(e) {
        if (e) {
          let index = this.mPasien.findIndex(x => x.value == e)
          let data = {
            type: "search-pasien",
            search: e,
          }

          Gen.apiRest(
            "/do/" + this.$parent.modulePage, {
              data: data
            },
            "POST"
          ).then(res => {
            if (!res.data.data) {
              return this.$swal({
                title: `Data Tidak Ditemukan`,
                text: 'Data Pasien Tidak Ditemukan',
                icon: 'error',
              })
            } else {
              let data = res.data.data
              this.rowPasien = data

              if (index !== -1) {
                this.row.ap_fullname = this.mPasien[index]['name']
                this.row.ap_nik = this.mPasien[index]['nik']
                this.row.ap_phone_number = this.mPasien[index]['ap_phone_number']
                this.row.ap_dom_address = this.mPasien[index]['ap_dom_address']
                this.row.ap_dom_rt = this.mPasien[index]['ap_dom_rt']
                this.row.ap_dom_rw = this.mPasien[index]['ap_dom_rw']
                this.row.ap_dom_kelurahan = this.mPasien[index]['ap_dom_kelurahan']
                this.row.ap_dom_kecamatan = this.mPasien[index]['ap_dom_kecamatan']
                this.row.ap_dom_kota = this.mPasien[index]['ap_dom_kota']
                this.row.ap_code = this.mPasien[index]['code']
                this.row.ap_gender = this.mPasien[index]['ap_gender']
                this.row.ap_dob = this.mPasien[index]['ap_dob']

              } else {
                this.row.ap_fullname = null
                this.row.ap_nik = null
                this.row.ap_phone_number = null
                this.row.ap_dom_address = null
                this.row.ap_dom_rt = null
                this.row.ap_dom_rw = null
                this.row.ap_dom_kelurahan = null
                this.row.ap_dom_kecamatan = null
                this.row.ap_dom_kota = null
                this.row.ap_code = null
                this.row.ap_gender = null
                this.row.ap_dob = null

              }
              this.changeJaminan()
            }
          })
        }
      },
      changePage(page, act) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this.mPasien[0]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this.mPasien[0]['totalRows'] / 10)) {
            page += 1
            this.mPasien[0]['currentPage'] += 1
          }
        }
        let data = {
          type: 'select-paging-pasien',
          page: page,
          search: this.searchData,
          nama_pasien: this.nama_pasien,
          no_rm: this.no_rm,
          alamat: this.alamat
        }
        Gen.apiRest(
          "/do/" + 'UGDReservasi', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mPasien = res.data.data
        })
      },
      getConfigDynamic(master, value, val = 'text') {
        let text = ''
        if (value) {
          let index = (master || []).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index][val]
          }
        }
        return text
      },
      ceilData(value) {
        return Math.ceil(value)
      },
      toValidate(val) {
        return {
          ...val
        }
      },
      apiGetMaster() {
        Gen.apiRest(
          "/get/" + this.$parent.modulePage + '/master'
        ).then(res => {
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
        })
      },

      changeJaminan(isLoad = false) {
        this.errData = ""
        this.errDataV2 = ""

        if (this.row.aures_jaminan == 3) {

          if ((this.row.ap_nik).length < 16) {
            return this.$swal({
              title: `Nomor NIK tidak Valid`,
              text: 'Silakan update Nomor NIK di menu pasien',
              icon: 'error',
            })
          }

          let data = {
            ap_id: this.row.aures_ap_id,
            nik: this.row.ap_nik,
            type: 'select-bpjs-value'
          }
          this.$parent.loadingOverlay = true

          Gen.apiRest(
            "/do/" + this.$parent.modulePage, {
              data: data
            }, "POST"
          ).then(res => {
            let data = res.data.data

            if (res.data.status == "fail") {
              this.isBPJS = false
              this.$parent.loadingOverlay = false
              this.errData =
                "NIK atau nomor kartu anda tidak ditemukan pada data BPJS. Silakan periksa NIK atau nomor kartu anda."
              return
            }

            this.isBPJS = true

            this.row.apbd_fullname = data.apbd_fullname
            this.row.apbd_nik = data.apbd_nik
            this.row.apbd_gender = data.apbd_gender
            this.row.apbd_dob = data.apbd_dob
            this.row.apbd_status = data.apbd_status
            this.row.aures_no_bpjs = data.apbd_no_bpjs
            this.listRujukan = data.listRujukan

            console.log(this.listRujukan)

            if (!(this.listRujukan || []).length && !this.row.arres_no_rujukan) {
              this.row.arres_no_rujukan = null
            }

            let statusBPJS = data.apbd_status
            if (statusBPJS == 'MENINGGAL' || statusBPJS == 'KELUAR KEMAUAN SENDIRI' || statusBPJS ==
              'TIDAK DITANGGUNG' || statusBPJS == 'PENANGGUHAN PESERTA' || statusBPJS ==
              'NON AKTIF DIAKHIR BULAN' || statusBPJS == 'PENANGGUHAN PEMBAYARAN' || statusBPJS ==
              'NON AKTIF KARNA DATA GANDA' || statusBPJS == 'USIA ANAK PPU > 21 TH DAN 25 TH' || statusBPJS ==
              'NON AKTIF KARNA PREMI') {
              this.errDataV2 = data.apbd_status
            } else {
              this.errDataV2 = ""
            }
            this.$parent.loadingOverlay = false
          }).catch(err => {
            this.$parent.loadingOverlay = false
          })
        } else {
          this.row.aures_start_kontrol = null
        }

      },
      undoTTD(refs,field){
        this.$refs[refs].clearSignature()
        this.row[field] = null
      },
      output(refs,field) {
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        if(!isEmpty){
          this.row[field] = data
        }
      },
    },
    watch: {
      row: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
      rowPasien: {
        handler(v) {
          this.autoSavePasien(v)
        },
        deep: true

      }
    }
  }
</script>